<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="费用信息" name="second">
      <div class="headerbut">
        <el-button class="seniorbut" @click="SubmitBut">提交</el-button>
        <el-button class="ordinarybut" @click="cancelbut">取消</el-button>
      </div>
      <div class="box2">
        <div class="headerTitle">渠道代理信息:</div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>账户名称 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.account"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>代理名称 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.agentName"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>所属仓库 :</div>
          </div>
          <div class="right_inpu">
            <el-select v-model="channeldata.storageId" class="queryInput">
              <el-option
                v-for="(item, index) in cangkuList"
                :label="item.storageName"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>代理全称 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.fullName"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>结算方式 :</div>
          </div>
          <div class="right_inpu">
            <el-select
              v-model="channeldata.settlementStatus"
              class="queryInput"
            >
              <el-option
                v-for="(item, index) in packageStatusList"
                :label="item.showName"
                :value="item.statusVal"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>银行账号 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.bankAccount"
              class="queryInput"
            ></el-input>
          </div>
        </div>

        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>联系人 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.contacts"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">联系电话:</div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.contactTel"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>银行名称 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.bankName"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">联系地址:</div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.contactAddress"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">手机号码:</div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.contactPhone"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">
            <div class="Required">*</div>
            <div>支行 :</div>
          </div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.subBranch"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">邮箱:</div>
          <div class="right_inpu">
            <el-input v-model="channeldata.email" class="queryInput"></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">备注:</div>
          <div class="right_inpu">
            <el-input
              v-model="channeldata.comment"
              class="queryInput"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="box2 box3">
        <div class="headerTitle">渠道代理联系人:</div>
        <el-table
          class="formbox"
          ref="channeldata.agentPhonebookVos"
          :data="channeldata.agentPhonebookVos"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#f0f0f0' }"
        >
          <el-table-column label="序号" width="80" type="index">
          </el-table-column>
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->

          <el-table-column
            prop="channeldata.agentPhonebookVos.contactName"
            label="联系人"
            width="180"
          >
            <template slot-scope="scope">
              <el-input
                class="frominput"
                v-model="
                  channeldata.agentPhonebookVos[scope.$index].contactName
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="channeldata.agentPhonebookVos.contactPhone"
            label="联系电话"
            width="180"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input
                class="frominput"
                v-model="
                  channeldata.agentPhonebookVos[scope.$index].contactPhone
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="channeldata.agentPhonebookVos.position"
            label="职位"
            width="150"
          >
            <template slot-scope="scope">
              <el-input
                class="frominput"
                v-model="channeldata.agentPhonebookVos[scope.$index].position"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="channeldata.agentPhonebookVos.otherContact"
            label="其他联系方式"
          >
            <template slot-scope="scope">
              <el-input
                class="frominput"
                v-model="
                  channeldata.agentPhonebookVos[scope.$index].otherContact
                "
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="操作" width="120">
            <template slot-scope="scope">
              <template v-if="scope.$index == 0">
                <el-button class="ordinarybut" @click="addbut(scope.$index)"
                  >新增</el-button
                >
              </template>
              <template v-else>
                <el-button class="ordinarybut" @click="deletebut(scope.$index)"
                  >删除</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { Api } from "@/assets/js/api";

import tool from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      channeldata: {
        account: "", //账户名称      必填
        agentName: "", //代理名称      必填
        agentPhonebookVos: [
          //渠道代理联系人
          {
            contactName: "", //渠道代理联系人
            contactPhone: "", //联系人姓名
            otherContact: "", //其他联系方式
            position: "", //职务
          },
        ],
        bankAccount: "", //银行账号      必填
        bankName: "", //银行名称      必填
        comment: "", //备注
        contactAddress: "", //联系人地址
        contactPhone: "", //联系人手机
        contactTel: "", //联系人电话
        contacts: "", //联系人        必填
        email: "", //邮箱
        fullName: "", //代理全称       必填
        settlementStatus: "", //结算类型 xian_jie:settlement_status=现结  必填
        storageId: "", //仓库id         必填
        subBranch: "", //支行           必填
      },
      cangkuList: [], //仓库下拉列表
      activeName: "second",
      packageStatusList: [
        {
          statusVal: "xian_jie:settlement_status",
          showName: "现结",
        },
        {
          statusVal: "yue_jie:settlement_status",
          showName: "月结",
        },
      ],
      pageType: "add", //提交状态(新增，修改)
    };
  },
  mounted() {
    let pageType = this.$route.query.pageType;
    let nid = this.$route.query.nid;
    this.pageType = pageType;
    if (pageType == "modify") {
      this.channelAgentDetail(nid);
    }
    this.getStorageList();
    //结算类型
    this.getStatusValList("customer_agent_v1.settlement_status");
  },
  methods: {
    getStatusValList(val) {
      let param = {
        tableAndFieldName: val,
      };
      Api.getStatusValList(param).then((res) => {
        // 包裹状态
        let packageStatusList = res.data.result || [];
        this.packageStatusList = packageStatusList;
      });
    },
    //渠道代理详情
    channelAgentDetail(customerAgentId) {
      Api.channelAgentDetail({ customerAgentId: customerAgentId }).then(
        (res) => {
          let channeldata = res.data.result || this.channeldata;
          //console.log(res.data.result)
          delete channeldata.settlementStatusShow;
          delete channeldata.storageName;
          this.channeldata = channeldata;
        }
      );
    },
    //取消
    cancelbut() {
      this.channeldata = {
        account: "", //账户名称      必填
        agentName: "", //代理名称      必填
        agentPhonebookVos: [
          //渠道代理联系人
          {
            contactName: "", //渠道代理联系人
            contactPhone: "", //联系人姓名
            otherContact: "", //其他联系方式
            position: "", //职务
          },
        ],
        bankAccount: "", //银行账号      必填
        bankName: "", //银行名称      必填
        comment: "", //备注
        contactAddress: "", //联系人地址
        contactPhone: "", //联系人手机
        contactTel: "", //联系人电话
        contacts: "", //联系人        必填
        email: "", //邮箱
        fullName: "", //代理全称       必填
        settlementStatus: "", //结算类型 xian_jie:settlement_status=现结  必填
        storageId: "", //仓库id         必填
        subBranch: "", //支行           必填
      };
    },
    //提交
    SubmitBut() {
      let channeldata = this.channeldata;
      let signst = tool.getSign(channeldata);
      channeldata.sign = signst;
      if (this.pageType == "modify") {
        Api.updateChannelAgent(channeldata).then((res) => {
          //console.log(res.data.status);
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            tool.closePage();
            this.reload();
            this.cancelbut();
            /* setTimeout(()=>{
                  Object.keys(channeldata).forEach(key=>{channeldata[key]=''})
              },1000) */
          }
        });
      } else {
        // 新增
        let param = {
          account: this.channeldata.account, //账户名称      必填
          agentName: this.channeldata.agentName, //代理名称      必填
          agentPhonebookVos: this.channeldata.agentPhonebookVos,
          bankAccount: this.channeldata.bankAccount, //银行账号      必填
          bankName: this.channeldata.bankName, //银行名称      必填
          comment: this.channeldata.comment, //备注
          contactAddress: this.channeldata.contactAddress, //联系人地址
          contactPhone: this.channeldata.contactPhone, //联系人手机
          contactTel: this.channeldata.contactTel, //联系人电话
          contacts: this.channeldata.contacts, //联系人        必填
          email: this.channeldata.email, //邮箱
          fullName: this.channeldata.fullName, //代理全称       必填
          settlementStatus: this.channeldata.settlementStatus, //结算类型 xian_jie:settlement_status=现结  必填
          storageId: this.channeldata.storageId, //仓库id         必填
          subBranch: this.channeldata.subBranch, //支行           必填
        };
        let signst = tool.getSign(param);
        param.sign = signst;
        Api.addChannelAgent(param).then((res) => {
          //console.log(res.data.status);
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            tool.closePage();
            this.reload();
            this.cancelbut();
            /* setTimeout(()=>{
                  Object.keys(channeldata).forEach(key=>{channeldata[key]=''})
              },1000) */
          }
        });
      }
    },
    //添加
    addbut() {
      this.channeldata.agentPhonebookVos.push({
        contactName: "", //渠道代理联系人
        contactPhone: "", //联系人姓名
        otherContact: "", //其他联系方式
        position: "", //职务
      });
    },
    //删除
    deletebut(e) {
      this.channeldata.agentPhonebookVos.splice(e, 1);
    },
    //所属仓库
    getStorageList() {
      Api.getStorageList().then((res) => {
        ////console.log(res.data.result)
        this.cangkuList = res.data.result || [];
      });
    },
  },
};
</script>

<style scoped lang="scss">
.headerbut {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.panel {
  width: 100%;
  margin: 20px auto;
  padding: 100px 20px;
  box-sizing: border-box;
  border: 1px solid slategrey;
}
.box3 {
  margin-top: 20px;
}
.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }
    .remarksinp {
      width: 50%;
    }
  }
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
.headerTitle {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
}
//能输入样式
.queryItem1 {
  min-width: 390px;
  width: 30%;
  margin: 3px 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;

  align-items: center;
  font-size: 14px;
  .FontStyle {
    color: #f00;
    cursor: pointer;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .queryLabel {
    width: 100px;
    min-width: 100px;
    color: #606266;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .right_inpu_post {
    position: relative;
    .niming {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99;
    }
  }
  .right_inpu {
    width: 100%;
  }
  .right_inpu ::v-deep {
    .el-input__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 100%;
    }
  }
  .right_inpu ::v-deep {
    .el-textarea__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 375px;
    }
  }
}
.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}
.Required {
  color: #f00;
}
</style>